<template>
  <div id="projects-section" class="w-full flex flex-wrap px-3 pt-20">
    <h3 class="text-3xl font-bold dark:text-white mb-4 w-full">Projects</h3>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full ">
      <div v-for="(project, index) in projects" :key="index" class="relative max-w-sm max-h-min p-6 bg-slate-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="w-24 h-24 mb-3 rounded shadow-lg object-contain" :src="project.image" alt="Bonnie image"/>
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ project.name }}</h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{{ project.description }}</p>
        <a :href="project.url" target="_blank" :class="project.url === '#' ? 'pointer-events-none text-gray-400 cursor-not-allowed' : ''" class="inline-flex items-center px-3 py-2 mb-5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          {{ project.url === '#' ?  "Coming soon" : "Check it out" }}
          <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </a>
        <div class="flex flex-wrap absolute bottom-3 left-6 ">
          <div :title="used.label" class="relative mr-3 mt-2 cursor-pointer" v-for="(used, index) in project.used" :key="index">
            <img class="w-6 h-6 rounded" :src="used.icon" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    props: { skills: Object},
    name: 'ProjectsSection',
    data() {
      return {
        projects: [
          {
            name: "Murimi Portfolio",
            image: require("../../assets/images/me.png"),
            url: "https://murimi.tsunairo.xyz",
            description: "This is a portfolio website to showcase my work experience, projects and skills.",
            used: [
              this.skills.programmingLanguages.find(library => library.label === "JavaScript"),
              this.skills.libraries.find(library => library.label === "Vue"),
              this.skills.libraries.find(library => library.label === "Tailwind")
            ]
          },
          {
            name: "Scenic Edge",
            image: "https://scenicedge.rest/Scenic%20Edge-logos.jpeg",
            url: "https://scenicedge.rest",
            description: "This is a restaurant website to showcase the services they offer and their menu.",
            used: [
              this.skills.programmingLanguages.find(library => library.label === "TypeScript"),
              this.skills.libraries.find(library => library.label === "Next"),
              this.skills.libraries.find(library => library.label === "Tailwind")
            ]
          },
          {
            name: "Germale",
            image: require("../../assets/images/logo.jpg"),
            url: "https://germale.com",
            description: "This is a SAAS application that will revolutionize the way poultry farming is done.",
            used: [
              this.skills.programmingLanguages.find(library => library.label === "Java"),
              this.skills.programmingLanguages.find(library => library.label === "JavaScript"),
              this.skills.libraries.find(library => library.label === "Spring Boot"),
              this.skills.libraries.find(library => library.label === "Next"),
              this.skills.libraries.find(library => library.label === "Tailwind"),
              this.skills.tools.find(library => library.label === "Postgres"),
            ]
          }
        ]
      }
    }
  }
</script>